import {
    DatePicker,
    DateTimePicker,
    LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
    Box,
    Checkbox,
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    Paper,
    Popper,
    TextField,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
    trimWhiteSpaceValidation,
    validationAsNumber,
} from "../../../js/utils/inputValidations";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";
import { createColor, ColorButton, ColorBox, useTranslate } from "mui-color";
import NumberFormat from "react-number-format";

import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import de from "date-fns/locale/de";
import nl from "date-fns/locale/nl";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import { DateOnlyToJSDate, getDateFormatPattern } from "../../../js/utils/genericMethods";

const datePickerLocalization = {
    ENG: en,
    FRA: fr,
    NLD: nl,
    DEU: de,
    SPA: es,
    POR: pt,
};

export const deativateAutocompletion = {
    autoComplete: "new-password",
    form: {
        autocomplete: "off",
    },
};

export const scrollToFieldname = (formState, fieldName) => {
    const allErrors = Object.keys(formState.errors);

    if (allErrors.length > 0) {
        const extractCurrentFieldName = allErrors.filter(e => e === fieldName);
        if (extractCurrentFieldName[0]) {
            let firstErrorElement = document.getElementsByName(
                extractCurrentFieldName[0]
            )[0];

            firstErrorElement.scrollIntoView({
                behavior: `smooth`,
                block: "center",
            });
        }
    }
};

export const searchForErrors = (fieldName, errors) => {
    const fieldKeys = fieldName.split(".");
    return fieldKeys.reduce((a, e) => {
        if (a && Object.keys(a).length) {
            const currentIndex = e.match(/\[\d+\]/g);
            const currentName = e.match(/^.*\[/g);
            const startingName = currentName?.length
                ? currentName[0].slice(0, -1)
                : undefined;
            if (
                currentIndex?.length === 1 &&
                Object.keys(a).includes(startingName)
            ) {
                const index = currentIndex[0].slice(1, -1);
                return a[startingName][index];
            }
            return a[e];
        }
        return a;
    }, errors);
};

/** Field used with a specific value that needs to be hidden to the user, it's either necessary for a validation (in the form), either it's an ID that needs to be passed on a request (is required in the model).  DOC*/
export const HiddenField = ({ fieldName, defaultValue = "", validation }) => {
    const methods = useFormContext();
    const { control } = methods;

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={controllerProps => {
                return <></>;
            }}
        />
    );
};

/** Basic text input */
export const VTextField = ({
    label,
    fieldName,
    validation = {},
    defaultValue = "",
    maxCar = 300,
    disabled,
    ...props
}) => {
    const methods = useFormContext();
    const { control, formState } = methods;
    const isLargeInput = maxCar >= 500;

    const inputRef = useRef(null);
    scrollToFieldname(formState, fieldName);

    if (
        validation?.required?.value ||
        (validation?.required !== undefined &&
            validation?.required?.value !== false)
    )
        validation = Object.assign(validation, trimWhiteSpaceValidation);

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={({ field, fieldState }) => (
                <TextField
                    {...field}
                    inputRef={inputRef}
                    id={fieldName}
                    multiline={isLargeInput}
                    minRows={isLargeInput ? 4 : 1}
                    maxRows={isLargeInput ? 8 : 1}
                    disabled={disabled}
                    label={label}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    fullWidth
                    error={fieldState.error ? true : false}
                    helperText={fieldState.error?.message}
                    {...props}
                />
            )}
        />
    );
};

export const VYearPicker = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    ...props
}) => {
    const methods = useFormContext();
    const { control } = methods;
    const locale = useSelector(state => state.i18n.locale);

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={({ field, fieldState }) => (
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={datePickerLocalization[locale]}
                >
                    <DatePicker
                        {...field}
                        views={["year"]}
                        slotProps={{
                            textField: {
                            id: fieldName,
                            label: label,
                            InputLabelProps: { shrink: true },
                            size: "small",
                            fullWidth: true,
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            },
                        }}
                        {...props}
                    />
                    {/* <DatePicker
                        {...field}
                        views={["year"]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id={fieldName}
                                label={label}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                fullWidth
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                            />
                        )}
                        {...props}
                    /> */}
                </LocalizationProvider>
            )}
        />
    );
};
export const VYearPickerNumber = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    ...props
}) => {
    const methods = useFormContext();
    const { setValue, formState } = methods;
    const [baseValue, setBaseValue] = useState(defaultValue);
    const locale = useSelector(state => state.i18n.locale);

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLocalization[locale]}
        >
            <HiddenField
                fieldName={fieldName}
                validation={validation}
                defaultValue={baseValue?.getFullYear()}
            />
            <DatePicker
                views={["year"]}
                onChange={e => {
                    setBaseValue(e);
                    setValue(fieldName, e?.getFullYear() ?? null, {
                    shouldDirty: true,
                    shouldValidate: true,
                    });
                }}
                value={baseValue}
                slotProps={{
                    textField: {
                    label: label,
                    InputLabelProps: { shrink: true },
                    size: "small",
                    fullWidth: true,
                    error: !!formState.errors[fieldName],
                    helperText: formState.errors[fieldName]?.message,
                    },
                }}
                {...props}
            />
            {/* <DatePicker
                views={["year"]}
                onChange={e => {
                    setBaseValue(e);
                    setValue(fieldName, e?.getFullYear() ?? null, {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                }}
                value={baseValue}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={label}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        fullWidth
                        error={formState.errors[fieldName] ? true : false}
                        helperText={formState.errors[fieldName]?.message}
                    />
                )}
                {...props}
            /> */}
        </LocalizationProvider>
    );
};


export const VTimeOnlyPicker = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    removeNowAsDefault,
    ...props
}) => {
    const methods = useFormContext();
    const { control, setValue } = methods;


    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={({ field, fieldState }) => {
                return (
                    <NumberFormat
                        format="##:##"
                        mask={['h', 'h', 'm', 'm']}
                        value={field.value ?? "00:00"}
                        label={label}
                        onChange={(e, v) => setValue(fieldName, e.target.value, { shouldDirty: true })}
                        customInput={TextField}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={fieldState.error ? true : false}
                        helperText={fieldState.error?.message}
                    />
                )
            }}
        />
    );
};

const nullDateOnly = ["01/01/0001", "99/99/9999", null, undefined];
export const VDateOnlyPicker = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    removeTodayAsDefault,
    type = "Date", // or "DateTime"
    setDate = null,
    ...props
}) => {
    const methods = useFormContext();
    const { control, watch, setValue, clearErrors, formState } = methods;
    const locale = useSelector(state => state.i18n.locale);
    if (!defaultValue && removeTodayAsDefault && !watch(fieldName)) {
        defaultValue = null;
    }

    if (nullDateOnly.includes(defaultValue)) {
        defaultValue = null;
    }
    const inputRef = useRef(null);
    scrollToFieldname(formState, fieldName);

    return (
        <Box component="div" ref={inputRef} name={fieldName}>
            <Controller
                control={control}
                name={fieldName}
                defaultValue={defaultValue}
                rules={{
                    ...validation,
                }}
                render={({ field, fieldState }) => (
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={datePickerLocalization[locale]}
                    >
                        <DatePicker
                            format={getDateFormatPattern(navigator.language)} // Updated prop name
                            mask={getDateFormatPattern(navigator.language).replace(/[a-zA-Z]/g, "_")} // Updated to match new format
                            {...field}
                            value={DateOnlyToJSDate(watch(fieldName) ?? defaultValue)}
                            onChange={(v, k) => {
                                if(setDate !== null){
                                    setDate(v);
                                }
                                if (v !== null) {
                                const date = new Date(v);
                                if (!isNaN(date.getTime()) && date.getTime() > 0) {
                                    setValue(
                                    fieldName,
                                    `${`0${date.getDate()}`.slice(-2)}/${`0${date.getMonth() + 1}`.slice(-2)}/${date.getFullYear()}`,
                                    { shouldDirty: true }
                                    );
                                    clearErrors(fieldName);
                                }
                                } else {
                                setValue(fieldName, null, { shouldDirty: true });
                                }
                            }}
                            slotProps={{
                                textField: {
                                id: fieldName,
                                label: label,
                                InputLabelProps: { shrink: true },
                                size: "small",
                                fullWidth: true,
                                error: !!fieldState.error,
                                helperText: fieldState.error?.message,
                                },
                            }}
                            {...props}
                        />
                        {/* <DatePicker
                            inputFormat={getDateFormatPattern(
                                navigator.language
                            )} //where e.g. "MM/dd/yyyy" is representing date format
                            mask={getDateFormatPattern(
                                navigator.language
                            ).replace(/[a-zA-Z]/g, "_")} //where e.g.  "__/__/____", is representing user input
                            {...field}
                            value={DateOnlyToJSDate(
                                watch(fieldName) ?? defaultValue
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    id={fieldName}
                                    label={label}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    fullWidth
                                    error={fieldState.error ? true : false}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                            onChange={(v, k) => {
                                if (v !== null) {
                                    const date = new Date(v);
                                    if (
                                        !isNaN(date.getTime()) &&
                                        date.getTime() > 0
                                    ) {
                                        setValue(
                                            fieldName,
                                            `${`0${date.getDate()}`.slice(
                                                -2
                                            )}/${`0${
                                                date.getMonth() + 1
                                            }`.slice(
                                                -2
                                            )}/${date.getFullYear()}`,
                                            { shouldDirty: true }
                                        );
                                        clearErrors(fieldName);
                                    }
                                } else {
                                    setValue(fieldName, null, {
                                        shouldDirty: true,
                                    });
                                }
                            }}
                            {...props}
                        /> */}
                    </LocalizationProvider>
                )}
            />
        </Box>
    );
};

export const VDateTimePicker = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    removeTodayAsDefault,
    ...props
}) => {
    const methods = useFormContext();
    const { control, watch } = methods;
    const locale = useSelector(state => state.i18n.locale);
    if (!defaultValue && removeTodayAsDefault && !watch(fieldName)) {
        defaultValue = null;
    }

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={({ field, fieldState }) => (
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={datePickerLocalization[locale]}
                >
                    <DateTimePicker
                        ampm={false}
                        {...field}
                        slotProps={{
                            textField: {
                            id: fieldName,
                            label: label,
                            InputLabelProps: { shrink: true },
                            size: "small",
                            fullWidth: true,
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            },
                        }}
                        {...props}
                    />
                    {/* <DateTimePicker
                        ampm={false}
                        {...field}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id={fieldName}
                                label={label}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                fullWidth
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                            />
                        )}
                        {...props}
                    /> */}
                </LocalizationProvider>
            )}
        />
    );
};

export const VTimePicker = ({
    label,
    fieldName,
    validation = {},
    defaultValue,
    removeTodayAsDefault,
    //type = "Date", // or "DateTime"
    ...props
}) => {
    const methods = useFormContext();
    const { control, watch } = methods;
    const locale = useSelector(state => state.i18n.locale);

    if (!defaultValue && removeTodayAsDefault && !watch(fieldName)) {
        defaultValue = null;
    }

    return (
        <Controller
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={validation}
            render={({ field, fieldState }) => (
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={datePickerLocalization[locale]}
                >
                    <DateTimePicker
                        {...field}
                        //views={["year"]}
                        slotProps={{
                            textField: {
                            id: fieldName,
                            label: label,
                            InputLabelProps: { shrink: true },
                            size: "small",
                            fullWidth: true,
                            error: !!fieldState.error,
                            helperText: fieldState.error?.message,
                            },
                        }}
                        {...props}
                    />
                    {/* <DateTimePicker
                        {...field}
                        //views={["year"]}
                        renderInput={params => (
                            <TextField
                                {...params}
                                id={fieldName}
                                label={label}
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                fullWidth
                                error={fieldState.error ? true : false}
                                helperText={fieldState.error?.message}
                            />
                        )}
                        {...props}
                    /> */}
                </LocalizationProvider>
            )}
        />
    );
};

export const VCheckBox = ({
    fieldName,
    label,
    validation,
    defaultValue = false,
    ...props
}) => {
    const methods = useFormContext();
    const { setValue, control } = methods;

    const handleChange = event => {
        setValue(fieldName, event.target.checked, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    return (
        <>
            <Controller
                control={control}
                name={fieldName}
                rules={validation}
                defaultValue={defaultValue}
                onChange={e => handleChange(e)}
                render={({ field, fieldState }) => {
                    const isError = Boolean(fieldState?.error?.message);
                    const errorMessage = isError
                        ? fieldState?.error?.message
                        : "";
                    return (
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                        {...props}
                                    />
                                }
                                label={label}
                            />
                            <div>
                                <FormHelperText error hidden={!isError}>
                                    {errorMessage}
                                </FormHelperText>
                            </div>
                        </FormControl>
                    );
                }}
            />
        </>
    );
};

export const VColorPicker = ({
    label,
    fieldName,
    validation = {},
    variant = "outlined",
    defaultValue = "",
}) => {
    const methods = useFormContext();
    const { control, watch, setValue } = methods;
    const locale = useSelector(state => state.i18n.locale);

    const [colorBoxAnchor, setColorBoxAnchor] = useState(null);
    const colorBoxOuvert = Boolean(colorBoxAnchor);
    const [color, setColor] = useState(
        createColor(watch(fieldName) ?? "#879aa2")
    );

    const translate = value => {
        return t(`packages.colorPicker`)[value] || value;
    };

    useTranslate(() => ({ i18n: locale, t: translate }));

    return (
        <>
            <Box>
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={defaultValue}
                    rules={validation}
                    render={({ field, fieldState }) => (
                        <TextField
                            {...field}
                            id={fieldName}
                            onClick={e => setColorBoxAnchor(e.currentTarget)}
                            name={fieldName}
                            label={label}
                            InputProps={{
                                readOnly: true,
                                style: {
                                    color: "black",
                                },
                                endAdornment: <ColorButton color={color} />,
                            }}
                            variant={variant}
                            error={fieldState.error ? true : false}
                            helperText={fieldState.error?.message}
                            fullWidth
                            size="small"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            </Box>
            {colorBoxOuvert && (
                <Popper
                    open={colorBoxOuvert}
                    anchorEl={colorBoxAnchor}
                    style={{
                        zIndex: 99,
                        minWidth: 320,
                    }}
                    placement={"bottom-start"}
                >
                    <ClickAwayListener
                        onClickAway={() => setColorBoxAnchor(null)}
                    >
                        <Paper elevation={6}>
                            <ColorBox
                                value={color}
                                disableAlpha
                                onChange={value => {
                                    setValue(
                                        fieldName,
                                        !value?.error ? `#${value?.hex}` : null,
                                        {
                                            shouldDirty: true,
                                            shouldValidate: !value?.error,
                                        }
                                    );
                                    setColor(value);
                                }}
                            />
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            )}
        </>
    );
};

export const VNumeric = ({
    fieldName,
    label,
    validation = {},
    variant = "outlined",
    defaultValue,
    isAnAmount = false,
    unit = isAnAmount ? "€" : "",
    integer = false,
    disabled = false,
    checkingError,
    ...props // onBlur, InputProps, fullwidth, type, helperText ( for mobile number), multiline
}) => {
    const convert = useCallback(
        value => {
            if (value === "-") return "-";
            if (!value || value === "") return "";
            return integer
                ? parseInt(Number(value))
                : Number(value.replace(",", "."));
        },
        [integer]
    );

    const cleanString = useCallback(
        value => {
            if (!value || value === "") return "";
            const trimmed = value.trim();
            return integer ? parseInt(trimmed).toString() : trimmed;
        },
        [integer]
    );

    const methods = useFormContext();

    const { formState, setValue, register, watch, control } = methods;
    const formValue = defaultValue
        ? watch(fieldName, defaultValue)
        : watch(fieldName);
    const [stringValue, setStringValue] = useState(String(formValue));

    useEffect(() => {
        register(fieldName, validation);
    }, [register, fieldName, validation]);

    useEffect(() => {
        if (defaultValue !== undefined) {
            setValue(fieldName, convert(String(defaultValue)));
            setStringValue(String(defaultValue));
        }
        // eslint-disable-next-line
    }, [defaultValue]); // , convert, fieldName, setValue

    Object.assign(validation, validationAsNumber());

    useEffect(() => {
        // convertir les nulls en string vide : setValueAsNumber => null === 0
        if (formValue === null || formValue === undefined) {
            setValue(fieldName, "");
            setStringValue("");
        } else if (stringValue === "-") {
            setValue(fieldName, "");
            setStringValue("-");
        } else if (isNaN(formValue)) setStringValue("");
        else if (!formState.isDirty) {
            const finalString = isNaN(formValue)
                ? stringValue
                : isAnAmount
                ? ((Number(String(formValue)) * 100) / 100).toFixed(2)
                : cleanString(String(formValue));

            setStringValue(finalString);
        }
        // eslint-disable-next-line
    }, [formValue]); // , isAnAmount, fieldName, cleanString, formState, setValue, stringValue

    const isError =
        checkingError?.isError ?? Boolean(formState.errors[fieldName]);
    const errorMessage = isError
        ? Boolean(checkingError?.message)
            ? checkingError?.message
            : formState.errors[fieldName]?.message
        : "";

    const inputRef = useRef(null);
    scrollToFieldname(formState, fieldName);

    const handleChange = useCallback(
        event => {
            const value = event.target.value;
            const converted = convert(value);
            if (converted === "") {
                setStringValue("");
                setValue(fieldName, "", { shouldDirty: true });
            } else if (converted === "-") {
                setStringValue("-");
                setValue(fieldName, "-");
            } else {
                const finalString = isNaN(converted)
                    ? stringValue
                    : cleanString(value);
                const finalValue = convert(finalString);
                setStringValue(finalString);
                // Slight delay to setValue on registered field, to optimize render refresh for user
                setTimeout(() => {
                    setValue(fieldName, finalValue, {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                }, 100);
            }
        },
        // eslint-disable-next-line
        [stringValue] // 'cleanString', 'convert', 'fieldName', and 'setValue'
    );

    return (
        <Box component="div" ref={inputRef} name={fieldName}>
            <TextField
                // ci-dessous, l'input obtient la vraie valeur du form si elle est définie et notamment lors d'un setValue() sur un champ numérique
                value={stringValue ?? ""}
                error={isError}
                disabled={disabled}
                helperText={errorMessage}
                label={label}
                onBlur={control.onBlur}
                variant={variant}
                size="small"
                onFocus={control.onFocus}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            sx={{ mt: 1 / 2, fontSize: "10px" }}
                            position="end"
                        >
                            <Box>{unit}</Box>
                        </InputAdornment>
                    ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ style: { textAlign: "right" } }}
                onChange={handleChange}
                fullWidth
                {...props}
            />
        </Box>
    );
};

export const MaskedTextfield = () => {
    const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
        props,
        ref
    ) {
        const { onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={ref}
                onValueChange={values => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                mask={"Lat:___________"}
                // customInput={TextField}
                thousandSeparator
                isNumericString
                prefix="$"
            />
        );
    });

    const [value, setValue] = useState("1320");

    const handleChange = event => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <TextField
            label="react-number-format"
            value={value}
            onChange={handleChange}
            name="numberformat"
            id="formatted-numberformat-input"
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            fullWidth
        />
    );
};

export const DatePickerGrid = ({
    fieldName,
    rowValue,
    error,
    onChange = (_event, _data) => {
        /*  */
    },
    ...props
}) => {
    const { id, value, api, field } = props;

    const locale = useSelector(state => state.i18n.locale);

    const handleChange = async event => {
        onChange(event);
        const model = api.getEditRowsModel();
        const other = model[id];
        api.setEditRowsModel(
            {
                [id]: {
                    ...other,
                    [field]: { value: event },
                },
            },
            event
        );
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLocalization[locale]}
        >
            <DatePicker
                value={value}
                format={getDateFormatPattern(navigator.language)} // Updated prop name
                onChange={event => handleChange(event)}
                slotProps={{
                    textField: {
                    variant: "outlined",
                    error: error,
                    sx: {
                        "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: error ? "2px" : "0px",
                        },
                    },
                    fullWidth: true,
                    },
                }}
                {...props}
            />
            {/* <DatePicker
                value={value}
                inputFormat={getDateFormatPattern(navigator.language)} // where e.g. "MM/dd/yyyy" is representing date format
                onChange={event => handleChange(event)}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={error}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: error ? "2px" : "0px",
                            },
                        }}
                        fullWidth
                    />
                )}
                {...props}
            /> */}
        </LocalizationProvider>
    );
};

export const DateOnlyPickerGrid = ({
    fieldName,
    error,
    onChange = (_event, _data) => {
        /*  */
    },
    ...props
}) => {
    const { id, value, api, field } = props;

    const locale = useSelector(state => state.i18n.locale);

    const handleChange = async event => {
        onChange(event);
        let date = new Date(event);
        if (!isNaN(date.getTime()) && date.getTime() > 0) {
            date = `${`0${date.getDate()}`.slice(-2)}/${`0${
                date.getMonth() + 1
            }`.slice(-2)}/${date.getFullYear()}`;
        }

        const model = api.getEditRowsModel();
        const other = model[id];
        api.setEditRowsModel(
            {
                [id]: {
                    ...other,
                    [field]: { value: date },
                },
            },
            event
        );
    };

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={datePickerLocalization[locale]}
        >
            <DatePicker
                {...props}
                value={DateOnlyToJSDate(value)}
                format={getDateFormatPattern(navigator.language)} // Updated prop name
                mask={getDateFormatPattern(navigator.language).replace(/[a-zA-Z]/g, "_")} // Updated to match new format
                onChange={event => handleChange(event)}
                slotProps={{
                    textField: {
                    variant: "outlined",
                    error: error,
                    sx: {
                        "& .MuiOutlinedInput-notchedOutline": {
                        borderWidth: error ? "2px" : "0px",
                        },
                    },
                    fullWidth: true,
                    },
                }}
            />
            {/* <DatePicker
                {...props}
                value={DateOnlyToJSDate(value)}
                inputFormat={getDateFormatPattern(navigator.language)} //where e.g. "MM/dd/yyyy" is representing date format
                mask={getDateFormatPattern(navigator.language).replace(
                    /[a-zA-Z]/g,
                    "_"
                )} //where e.g.  "__/__/____", is representing user input
                onChange={event => handleChange(event)}
                renderInput={params => (
                    <TextField
                        {...params}
                        variant="outlined"
                        error={error}
                        sx={{
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderWidth: error ? "2px" : "0px",
                            },
                        }}
                        fullWidth
                    />
                )}
            /> */}
        </LocalizationProvider>
    );
};

