import Axios from "axios";
import { toastr } from "react-redux-toastr";
import { store } from "../redux/store";
/* 2.3 Data management and fetching  DOC*/


export const genericError = {
    notFound: "Status 404: The request sent to the server is unvalid.",
    other: "A problem occurred and no message was provided. Check the frontend request or backend or use the backend debugger",
};

const {
    REACT_APP_API_PORT,
    REACT_APP_API_PORT_AZURE,
    REACT_APP_API_DOMAIN,
    REACT_APP_API_DOMAIN_AZURE,
} = process.env;

export const DOMAIN = REACT_APP_API_DOMAIN_AZURE
    ? REACT_APP_API_DOMAIN_AZURE
    : REACT_APP_API_DOMAIN;

export const PORT = REACT_APP_API_PORT_AZURE
    ? REACT_APP_API_PORT_AZURE
    : REACT_APP_API_PORT;

export const domain_port = DOMAIN && PORT ? `${DOMAIN}:${PORT}` : "";
export const backendURL = domain_port ? `https://${domain_port}` : "";

function isUserSessionStillValid() {
    let lastAuthentication = store.getState().auth.Since;
    lastAuthentication = lastAuthentication
        ? new Date(lastAuthentication)
        : new Date(2000, 1, 1);
    const numberHours =
        (new Date().getTime() - lastAuthentication.getTime()) /
        (1000 * 3600);
    
    if (numberHours <= 1) {
        return true
    } else if (numberHours > 1) {
        return false
    }

}

/** Used along the toastr-redux package to display a user information about the error from the server */
export function errorMessageDisplay(error) {
    if (error.response.status === 404) {
        return genericError.notFound;
    } else if (typeof error.response.data === "string") {
        return error.response.data;
    } else if (typeof error.response.data.message === "string") {
        return error.response.data.message;
    } else if (typeof error.response.data.Message === "string") {
        return error.response.data.Message;
    } else {
        return genericError.other;
    }
}

/** A PUT Promise used to update a specific resource identified on the server.  DOC*/
export function editModel(newObject, id, token, url) {
    return new Promise((resolve, reject) => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        Axios.put(url + "/" + id, newObject, config)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                toastr.error(errorMessageDisplay(error));
                reject();
            });
    });
}

/** A POST promise used to create a new resource on the server. DOC*/
export function addNewModel(newObject, token, url) {
    return new Promise((resolve, reject) => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        Axios.post(url, newObject, config)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                console.error(error);
                toastr.error(errorMessageDisplay(error));
                reject();
            });
    });
}

/** A DELETE promise used to delete a specific resource on the server, DOC*/
export function deleteModel(rowid, token, url) {
    return new Promise((resolve, reject) => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        Axios.delete(url + "/" + rowid, config)
            .then(function (response) {
                resolve();
            })
            .catch(function (error) {
                console.error(error);
                toastr.error(errorMessageDisplay(error));
                reject();
            });
    });
}

/** A GET or POST promise to retrieve usually a page in a grid. DOC*/
export function fetchDataGrid(
    token,
    query,
    baseURL,
    optionalFetchOptions,
    bodyOfRequest = false
) {
    return new Promise((resolve, _reject) => {
        let url = baseURL + "?";
        url += "PageSize=" + query.pageSize;
        url += "&PageNumber=" + (query.pageNumber + 1);
        url += "&Search=" + query.search?.replace("#", "");
        if (optionalFetchOptions) {
            url += "&" + optionalFetchOptions;
        }
        if (Boolean(bodyOfRequest) && bodyOfRequest.Filters) {
            url += "&" + bodyOfRequest.Filters;
        }

        if (query.orderBy) {
            url += "&orderBy=" + query.orderBy; //
            url += "&dir=" + query.orderDirection; //
        }

        const fetching = () => {
            let config = {
                headers: { Authorization: "Bearer " + token },
            };
            if (Boolean(bodyOfRequest)) {
                return Axios.post(url, bodyOfRequest, config);
            }
            return Axios.get(url, config);
        };
        const isConnected = store.getState().auth.IsConnected;
        const validToken = isUserSessionStillValid()
        
        if (isConnected && validToken) {
            fetching()
                .then(function (response) {
                    resolve({
                        data: response.data.Data,
                        page: response.data.PageIndex - 1,
                        totalCount: response.data.TotalCount,
                        totalPages: response.data.TotalPages,
                    });
                })
                .catch(function (error) {
                    resolve({ data: [], page: 0, totalCount: 0 });
                    toastr.error(errorMessageDisplay(error));
                });
        }
    });
}

/** A GET promise used to retrieve data from the server. DOC*/
export const fetchData = async (
    route,
    token,
    type,
    ID,
    paramURL = "",
    search = "",
    silent = false
) => {
    const config = {
        headers: { Authorization: "Bearer " + token },
    };
    const urls = {
        ID: `${route}/${ID}`,
        Select: `${route}/Select`,
        SelectDetail: `${route}/Select/detail`,
        List: `${route}`,
    };
    let url = `${urls[type]}?${paramURL}&Search=${search?.replace("#", "")}`;
    const isConnected = store.getState().auth.IsConnected;
    const validToken = isUserSessionStillValid();
    try {
        if (isConnected && validToken) {
            return await Axios.get(url, config);
        } else {
            return null;
        }
    } catch (error) {
        if (!silent) {
            toastr.error(errorMessageDisplay(error));
        }
        console.error(error);
    }
};

/** Used to download documents with POST (chunk), or just preview it in the browser, depending of the parameter 'mode' (either 'preview', either 'download') DOC*/
export function downloadFile(token, mode, baseURL) {
    let form = document.createElement("form");
    form.method = "post";
    form.action = `${backendURL}/media/${mode}${baseURL}`;
    if (mode === "preview") {
        form.target = "_blank";
    }
    form.innerHTML =
        '<input type="hidden" name="jwtToken" value="' + token + '">';

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
}

/** A PUT promise used to send a selection of objects to the server in order to pick them for further use. */
export async function requestWithSelectedObjects(
    url,
    dataDocumentBody,
    token,
    paramURL = ""
) {
    const config = {
        headers: { Authorization: "Bearer " + token },
    };
    let urlRequest = `${url}/pick?${paramURL}`;
    return await (
        await Axios.put(urlRequest, dataDocumentBody, config)
    ).data;
}

export const excelSpreadsheetMIME = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
export const jsonMIME = "application/json"

/** GET and download documents by stream. Type is the mime type of an excel spreadsheet usually.   */ 
export function getDownload(
    url,
    token,
    type, 
    fileName = "Excel",
    bodyOfRequest = null,
    setDownloadingFile = null
) {
    const fetching = () => {
        let config = {
            headers: { Authorization: "Bearer " + token },
            responseType: "arraybuffer",
        };
        if (Boolean(bodyOfRequest)) {
            return Axios.post(url, bodyOfRequest, config);
        }
        setDownloadingFile && setDownloadingFile(true);
        return Axios.get(url, config);
    };

    fetching().then(response => {
        const encodedUri = window.URL.createObjectURL(
            new Blob([response.data], { type: type })
        );
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        if(fileName==='/Family'){
            link.setAttribute("download", 'Family');
        }
        else if(fileName==='/Region'){
            link.setAttribute("download", 'Region');
        }
        else if(fileName==='/Branch'){
            link.setAttribute("download", 'Branch');
        }
        else if(fileName==='/Country'){
            link.setAttribute("download", 'Country');
        }
        else if(fileName==='/ref_analyte'){
            link.setAttribute("download", 'Analyte');
        }
        else{
            link.setAttribute("download", fileName.replace("/Ref_",""));
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadingFile && setDownloadingFile(false);
    });
}

export async function getErrorMessage(token, zipFileID) {
    const config = {
        headers: { Authorization: "Bearer " + token },
    };
    let urlRequest = `bulkupload/get-error-message/${zipFileID}`;
    return await Axios.get(urlRequest, config);
}
